export default [
  {
    duration: 0.25,
    price: 17.99,
    savings: null,
    totalCost: 17.99,
    type: "1w",
  },
  {
    duration: 1,
    price: 39.99,
    savings: "57%",
    totalCost: 39.99,
    type: "1m",
  },
  {
    duration: 3,
    price: 22.33,
    savings: "71%",
    totalCost: 66.99,
    type: "3m",
  },
  // {
  // 	duration: 6,
  // 	price: 14.99,
  // 	savings: "50%",
  // 	totalCost: 89.94,
  // 	type: "6m"
  // },
  {
    duration: 12,
    price: "12.50",
    savings: "81%",
    totalCost: 149.99,
    type: "12m",
  },
];
