import React from 'react';
import ModamilyAppTextInput from '../form/ModamilyAppTextInput';
import getLocation from '../../services/googleMaps';
import countries from '../../constants/countries';
import ModamilyAppLoadingComponent from '../common/ModamilyAppLoadingComponent';
import ModamilyAppDropDownMenu from '../form/ModamilyAppDropDownMenu';

/**
 * @property {Object} data  - { country: "", zipcode: null, latLong: null  } - default data for the form
 * @property {Function} invalidate - Function that gets called when the form is invalid
 * @property {Function} onValidChange - Function that gets called when the form is valid
 */
export default class ModamilyAppHometownForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        country: typeof this.props.data.country === 'string' ? this.props.data.country : '',
        zipcode: this.props.data.zipcode || null,
        latLong: this.props.data.latLong || null,
      },
    };
  }

  componentDidMount() {
    const { type } = this.props;
    if (type !== 'UPDATE_SEARCH_PREFERENCES' && !this.props.data.country) {
      this.checkGeolocation();
    } else {
      this.validateCallback();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  validateCallback = () => {
    const { data } = this.state;
    const { validation, invalidate, onValidChange } = this.props;

    const country = data.country || '';

    if (validation === 'custom') {
      onValidChange({ ...data, country });
    } else if (country && data.zipcode) {
      onValidChange({ ...data, country });
    } else if (!data.zipcode) {
      onValidChange({ country, zipcode: null });
    } else {
      invalidate();
    }
  };

  checkGeolocation = () => {
    if ('geolocation' in navigator) {
      this.setState({ isLoading: true });

      navigator.geolocation.getCurrentPosition((position) => {
        let location = {
          city: '',
          country: '',
          zipcode: '',
          latLong: position.coords.latitude + ',' + position.coords.longitude,
        };

        getLocation(position.coords).then((data) => {
          const res = JSON.parse(data).results;
          console.log(res, 'response from google');
          if (res.length === 0) {
            window.alert('Sorry, an error occurred. \nPlease try again later');
            this.setState({ data: location }, this.validateCallback);
            return null;
          }

          for (let index = 0; index < res.length; index++) {
            const locationObj = res[index];
            if (locationObj.address_components) {
              for (let y = 0; y < locationObj.address_components.length; y++) {
                const address_components = locationObj.address_components[y];
                if (address_components.types.includes('locality')) {
                  location.city = address_components.long_name;
                } else if (address_components.types.includes('country')) {
                  location.country = address_components.long_name;
                } else if (address_components.types.includes('postal_code')) {
                  location.zipcode = address_components.long_name;
                }

                if (location.zipcode && location.city && location.country) {
                  break;
                }
              }
              if (location.zipcode && location.city && location.country) {
                break;
              }
            }
          }

          this.setState({ data: location, isLoading: false }, this.validateCallback);
        });

        this.setState({ isLoading: false });
      });
    } else {
      this.setState({ isLoading: false });
    }
  };

  updateName = (key, text) => {
    if (!text && key !== 'zipcode') {
      return this.props.invalidate();
    }

    this.setState(
      ({ data }) => ({
        data: { ...data, [key]: text || '' },
      }),
      this.validateCallback,
    );
  };

  onCountrySelect = (selectedCountry) => {
    if (!selectedCountry || !countries.countryNames.includes(selectedCountry)) {
      console.warn('Invalid country selection');
      return;
    }

    this.setState(
      ({ data }) => ({
        data: { ...data, country: selectedCountry },
      }),
      () => {
        this.validateCallback();
      },
    );
  };

  render() {
    const { isLoading, data } = this.state;

    if (isLoading) return <ModamilyAppLoadingComponent />;
    return (
      <div className='currentLocationForm'>
        <div className='ModamilyAppOnboarding__info'>
          <h2 className='ModamilyAppOnboarding__info--title'>Where do you live?</h2>
        </div>

        <div className='locationDropDowwnContainer'>
          <ModamilyAppDropDownMenu
            key={data.country}
            selected={countries.countryNames.findIndex((countryName) => countryName?.toLowerCase().trim() === data.country?.toLowerCase().trim())}
            data={countries.countryNames}
            title='Select Country'
            type='Country'
            onItemClick={(country) => this.onCountrySelect(country)}
          />

          <div className='ModamilyAppOnboarding__work--role'>
            <p>ie. United States</p>
          </div>
        </div>

        <div className='zipcodeInputContainer'>
          <ModamilyAppTextInput
            onContentChange={(text) => {
              this.updateName('zipcode', text);
            }}
            value={data.zipcode}
            label='Zip Code'
          />
          <div className='ModamilyAppOnboarding__work--role'>
            <p>Zip Code</p>
          </div>
        </div>
      </div>
    );
  }
}
